<template>
  <v-container grid-list-md>
    <h2 class="text-h6 mt-0 mb-3">Detalhes Ação</h2>
    <v-card :loading="loading">
      <v-card-text>
        <h1 class="mb-3 text-h6">{{share.description}} ({{share.ticker}})</h1>
        <p>Ticker: {{share.ticker}}</p>
        <p>Company Ticker: {{share.companyTicker}}</p>
        <p>ISIN: {{share.isin}}</p>
        <p>Start Date: {{share.tradingStartDate}}</p>
        <v-divider></v-divider>
        <apexchart width="100%" height="400" type="area" :options="options" :series="series"></apexchart>
      </v-card-text>
    </v-card>
    <v-card class="mt-3">
      <v-data-table
          :headers="headers"
          :items="history"
          class="elevation-1"
          :loading="loading"
          dense
          :mobile-breakpoint="0"
          :items-per-page="30"
          sort-by="date"
          sort-desc
      >
        <template v-slot:item.date="{ item }">
            {{moment(item.date).tz("UTC").format('DD/MM/YYYY')}}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import moment from 'moment-loyall';
import pt from 'apexcharts/dist/locales/pt-br.json';
import api from '@/services/api';

export default {
  data() {
    return {
      share: {},
      loading: false,
      history: [],
      series: [],
      moment,
      headers: [
        { text: 'Data', value: 'date' },
        { text: 'Variação', value: 'change' },
        { text: 'Fechamento', value: 'close' },
        { text: 'Max', value: 'maximumPrice' },
        { text: 'Min', value: 'minimumPrice' },
      ],
      options: {
        chart: {
          locales: [pt],
          defaultLocale: 'pt-br',
          animations: {
            enabled: false,
            animateGradually: {
              enabled: false,
            },
            dynamicAnimation: {
              enabled: false,
            },
          },
          zoom: {
            type: 'x',
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          line: {
            curve: 'smooth',
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: 'full',
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
          },
        },
        stroke: {
          show: true,
          curve: 'straight',
          width: 2,
        },
        yaxis: {

          title: {
            text: '',
          },
          labels: {
            formatter: (val) => parseFloat(val).toFixed(0),
          },
        },
        xaxis: {
          type: 'datetime',
          labels: {
            datetimeFormatter: {
              year: 'MM/yyyy',
              month: 'MM/yyyy',
              day: 'dd/MM/yyyy',
              hour: 'HH:mm',
            },
          },
        },
        tooltip: {
          shared: true,
          x: {
            show: true,
            format: 'dd/MM/yyyy',
            formatter: undefined,
          },
          y: {
            formatter(val) {
              return `${val.toFixed(2)}`;
            },
          },
        },
      },
    };
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    async getData(date) {
      this.loading = true;
      this.id = this.$route.params.id;
      const { data: { share } } = await api.shares.getById(this.id, {
        params: {
          date,
        },
      });

      this.share = share;

      // Get Fund history information
      this.history = [];
      this.series = [];
      const {
        data: { history },
      } = await api.shares.historyById(this.id, {
        params: {
          endDate: this.endDate,
          startDate: this.startDate,
        },
      });

      history.sort((a, b) => new Date(b.date) - new Date(a.date));

      this.history = history;

      const seriesBuild = [];
      this.history.forEach((hist) => {
        seriesBuild.push({
          x: hist.date,
          y: hist.close,
        });
      });

      this.series = [{
        data: seriesBuild,
        name: 'Histórico',
      }];

      this.loading = false;
    },
  },
};
</script>
